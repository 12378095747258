<template>
<div class="home-page">
  <div class="post-content">
      <b>Willkommen in der Online-Akademie für Ausbau und Fassade!</b>
<br><br>
      Hier bieten wir Ihnen ein Spektrum an Kursen und Veranstaltungen an, die auf die Bedürfnisse von Fachleuten und Interessierten im Bereich Ausbau und Fassade zugeschnitten sind. Unser Ziel ist es, Ihnen fundiertes Wissen und praktische Fähigkeiten zu vermitteln, die Sie in Ihrer beruflichen Entwicklung unterstützen.
<br><br>
      Erkunden Sie unser Angebot in Ihrem eigenen Tempo und finden Sie Inhalte, die Ihrer beruflichen Weiterbildung dienen. Bei Fragen oder für weitere Informationen stehen wir Ihnen gerne zur Verfügung.    </div>
</div>
</template>

<script>

export default {
  name: "Home",
  components: {}
}
</script>

<style scoped>

</style>
